import currency from 'currency.js';
import React, { useEffect, useMemo, useState } from 'react';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaArrowRight, FaBarcode, FaCheck, FaCreditCard, FaEnvelopeOpen, FaLock, FaShieldAlt, FaSpinner, FaUnlock } from 'react-icons/fa';
import { FaPix } from 'react-icons/fa6';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, CustomInput, FormFeedback, FormGroup, Input, InputGroup, InputGroupAddon, Label, Row } from 'reactstrap';
import AutoComplete from '../../Layout/AppMain/Components/AutoComplete';
import InputCurrency from '../../Layout/AppMain/Components/InputCurrency';
import InputMask from '../../Layout/AppMain/Components/InputMask';
import { ModalLoading } from '../../Layout/AppMain/Components/LoadingIcon';
import ModalDialog from '../../Layout/AppMain/Components/ModalDialog';
import ReactSelect from '../../Layout/AppMain/Components/ReactSelect';
import { ErrorToast, SuccessToast } from '../../Layout/AppMain/Components/Toast';
import { setLoading } from '../../reducers/AppConfig';
// import { optionsTypePayment } from '../../services/defaultOptions';
import '../../Layout/AppMain/Components/CardTypePayment/style.css';
import Websocket from '../PaymentLinkPub/Websocket';

import { dateNow, formatCpfCnpj, formatDate, formatFloat, formatValue, isCpfCnpj, isMobile, onlyNumber } from 'nfutils';
import validator from 'validator';
import { CardIcons } from '../../Layout/AppMain/Components/CardIcons/Index';
import api from '../../services/api';
import buscaCep from '../../services/apiCep';
import { cardsMasks } from '../../services/cardsMasks';
import CardInputToken from './components/CardInputToken';

let interval = null

const PaymentLinkPub = ({ match, setLoading, history }) => {
  const { id } = match.params
  const [validation, setValidation] = useState({});
  const [updateAddress, setUpdateAddress] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentImg, setCurrentImg] = useState(0);
  const [charge, setCharge] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showCartRecovery, setShowCartRecovery] = useState(true);
  const [timeleftToRedirect, setTimeleftToRedirect] = useState(10);

  const [ state, setState ] = useState({
    name: '',
    description: '',
    fixed_value: false,
    value: '',
    payment_by: 1,
    max_quantity_installments: 1,
    payment_by_invoice: true,
    payment_by_card: true,
    payment_by_pix: true,
    hybrid_payment: false,
    payment_by_pix_and_card: false,
    value_credit_card: '',
    value_pix: '',
    msg_value_pix: '',
    type_payment: 1,
    cycle: null,
    quantity_installments: { value: 1, label: 'À vista (R$ 0,00)' },
    payment_link_images: [],
    checkedBumps: [],
    coupon_code: '',
    payment_link_discount_coupons: [],
    valid_from: '',
    valid_until: '',
    company: {},
    customer: {
      email: '',
      name: '',
      cpfcnpj: '',
      phone: '',
      cellphone: '',
      cep: '',
      address: '',
      number: '',
      complement: '',
      neighborhood: '',
      city: null,
      state: null
    },
    card: {
      cpfcnpj: '',
      name: '',
      cvv: '',
      month: '',
      year: ''
    },
  });
  const [optionsInstallments, setOptionsInstallments] = useState({
    1: { value: 1, label: 'À vista (R$ 0,00)' }
  });
  const optionsCycle = {
    1: { value: 1, label: 'Semanal' },
    2: { value: 2, label: 'Quinzenal' },
    3: { value: 3, label: 'Mensal' },
    4: { value: 4, label: 'Bimestral' },
    5: { value: 5, label: 'Trimestral' },
    6: { value: 6, label: 'Semestral' },
    7: { value: 7, label: 'Anual' }
  }

  const [selectedCard, setSelectedCard] = useState(null);
  const [totalValue, setTotalValue] = useState(0);
  const [coupon_code, setCouponCode] = useState('');
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [bumpAmounts, setBumpAmounts] = useState([]);
  const [isValidCoupon, setIsValidCoupon] = useState(false);
  const [originalValue, setOriginalValue] = useState(0);
  const [scripts, setScripts] = useState([]);

  const valueWithInterestOnOriginalValueWithBumpsAndDiscounts = useMemo(() => {
    const totalBumpAmount = bumpAmounts.reduce((acc, curr) => acc + curr, 0);
    const discountAmount = discountPercentage;

    return currency(originalValue)
    .subtract(currency(discountAmount))
    .add(state.quantity_installments.value > 1 ? currency(originalValue - discountAmount).multiply(state.interest_rate / 100) : 0)
    .add(currency(totalBumpAmount))
    .value;

}, [bumpAmounts, discountPercentage, originalValue, state.payment_by, state.quantity_installments]);

  const handleChange = (value, prop) => {
    let newState = {...state}
    newState[prop] = value

    if (prop === 'value_pix') {
        const intStateValue = Math.round(valueWithInterestOnOriginalValueWithBumpsAndDiscounts * 100);
        const intValue = Math.round(value * 100);
        newState.value_credit_card = (intStateValue - intValue) / 100;
    }

    setState(newState)
  }

  const handleChangeCard = (value, prop) => {
    let card = {...state.card}
    let customer = {...state.customer}
    card[prop] = value

    if (['name', 'cpfcnpj'].includes(prop)) {
      customer[prop] = value
    }

    setState({ ...state, card, customer })
  }

  const handleChangeCustomer = (value, prop) => {
    let customer = {...state.customer}
    customer[prop] = value

    if (prop === 'cep' && onlyNumber(value).length === 8) {
      setUpdateAddress(!updateAddress)
    }
    if (prop === 'state') {
      customer.city = null
    }
    if (prop === 'city' && value) {
      customer.state = {
        value: value.data.state.id,
        label: value.data.state.sigla,
        data: value.data.state
      }
    }

    setState({ ...state, customer })
  }

  const handleCheckboxChange = (event, bump) => {
    const { checked } = event.target;
    const { product_value } = bump;

    setState((prevState) => {
        const currentValueState = Number(prevState.value);
        const productValueNumber = parseFloat(product_value, 10);
        const newValue = checked ? currentValueState + productValueNumber : currentValueState - productValueNumber;
        if (checked) {
            setBumpAmounts((prevBumpAmounts) => [...prevBumpAmounts, productValueNumber]);
        } else {
            setBumpAmounts((prevBumpAmounts) => prevBumpAmounts.filter((amount) => amount !== productValueNumber));
        }

        return {
            ...prevState,
            value: newValue,
        };
    });
    updateCreditCardValue();
  };

  const updateCreditCardValue = () => {
    setState((prevState) => ({
        ...prevState,
        value_credit_card: prevState.value - prevState.value_pix,
    }));
  };

  const applyDiscount = (discountPercentage) => {
    const paymentLinkValue = currency(originalValue);
    const discountAmount = paymentLinkValue.multiply(currency(discountPercentage).divide(100));
    const currentValue = currency(state.value);
    const newValue = currentValue.subtract(discountAmount);

    setDiscountPercentage(discountAmount);
    setState((prevState) => ({
        ...prevState,
        value: newValue,
    }));
    updateCreditCardValue();
  };

  const validateCouponCode = () => {
    const coupon_code_trimmed = coupon_code.replace(/\s/g, '')
    if (coupon_code_trimmed !== coupon_code) {
      setCouponCode(coupon_code_trimmed)
    }

    const coupon = state.payment_link_discount_coupons.find(c => c.coupon_code === coupon_code_trimmed);
    if (coupon) {
      const validFrom = formatDate({ value: coupon.valid_from, location: 'En', format: 'Date'});
      const validUntil = formatDate({ value: coupon.valid_until, location: 'En', format: 'Date'});
      const now = new Date();

      if (validFrom > now || validUntil < now || !coupon.active) {
        ErrorToast({ placeholder: 'Cupom expirado' })
        setIsValidCoupon(false);
        return
      }
      if (!isValidCoupon) {
        setIsValidCoupon(true);
        applyDiscount(coupon.discount_percentage);
        setState((prevState) => ({
            ...prevState,
            coupon_code: coupon_code_trimmed,
        }));
        SuccessToast({ placeholder: `Cupom válido! Desconto de ${coupon.discount_percentage}% aplicado!` });
      } else {
        SuccessToast({ placeholder: 'Desconto já aplicado!' });
      }
    } else {
      ErrorToast({ placeholder: 'Cupom inválido' })
      setIsValidCoupon(false);
    }
  };

  const removeDiscount = () => {
    if (isValidCoupon) {
      setIsValidCoupon(false);
      setCouponCode('');
      setState((prevState) => ({
        ...prevState,
        value: Number(prevState.value) + Number(discountPercentage),
        coupon_code: '',
      }));
      setDiscountPercentage(0);
      updateCreditCardValue();
    }
  };

  const handleRedirect = () => {
    if(interval) {
        clearInterval(interval);
    }

    if(state.url) {
      interval = setInterval(() => {
        if(timeleftToRedirect > 0) {
            setTimeleftToRedirect(timeleftToRedirect - 1);
        }

        if(timeleftToRedirect === 0 ) {
          window.location.href = state.url;
        }
      }, 1000);
    };
  }

  const handleBack = () => {
    history.push('/');
  }

  /**
   * Função responsável por válidar os campos do formulário
   *
   * @param level Número inteiro indicando o tipo de validação
   *              - 1 = Valida os campos da tela inicial do formulário
   *              - 2 = Valida os campos da tela de cartão
   *              - 3 = Valida os campos da tela de informações do comprador
   *              - 4 = Valida um campo específico
   *              - 5 = Valida todos os campos do formulário
   *              - 6 = Valida os campos da tela de recuperação do carrinho
   * @param input Indica o campo que deve ser válidado (Quando level = 4)
   *
   * @return boolean
   */
  const handleValidate = (level, input) => {
    let invalid = {}
    if (!!input) {
      invalid = { ...validation }
      invalid[input] = false
    }

    //Valida o valor digitado
    if ([1, 4, 5].includes(level)) {
      if (!state.fixed_value && (!state.value || (state.value <= 0) || (state.value < 15 && state.payment_by === 4) || (state.value < 15 && state.payment_by === 1) || (state.value < 15 && state.payment_by === 2)) && (!input || input === 'value')) {
        invalid.value = true;
      }
    }

    //Valida os campos do cartão de crédito
    if ([2, 4, 5].includes(level) && [2, 5].includes(state.payment_by)) {
        if (!isCpfCnpj(state.card.cpfcnpj) && (input === 'card_cpfcnpj' || !input)) {
        invalid.card_cpfcnpj = true
      }
      if ((!state.card.name || !state.card.name.trim() || state.card.name.length < 3 || (state.card.name.split(' ')).length < 2) && (input === 'card_name' || !input)) {
        invalid.card_name = true
      }
      if ((!state.card.number || !state.card.number.trim() || state.card.number.length < 19 || (state.card.number.split(' ')).length < 4) && (input === 'card_number' || !input)) {
        invalid.card_number = true
      }
      if ((!state.card.month || !state.card.month.trim() || state.card.month.length !== 2 || state.card.month < 1 || state.card.month > 12) && (input === 'card_month' || !input)) {
        invalid.card_month = true
      }
      if ((!state.card.year || !state.card.year.trim() || onlyNumber(state.card.year).length !== 4 || state.card.year < dateNow(null, 'YYYY')) && (input === 'card_year' || !input)) {
        invalid.card_year = true
      }
      if ((!state.card.cvv || !state.card.cvv.trim() || state.card.cvv.length < 3 || state.card.cvv.length > 4) && (input === 'card_cvv' || !input)) {
        invalid.card_cvv = true
      }
    }

    //Valida os campos do comprador
    if ([3, 4, 5].includes(level)) {
      if ((!state.customer.name || !state.customer.name.trim() || state.customer.name.length < 3) && (input === 'customer_name' || !input)) {
        invalid.customer_name = true
      }
      if (!validator.isEmail(state.customer.email) && (input === 'customer_email' || !input)) {
        invalid.customer_email = true
      }
      if (!isCpfCnpj(state.customer.cpfcnpj) && (input === 'customer_cpfcnpj' || !input)) {
        invalid.customer_cpfcnpj = true
      }
      if ((!state.customer.cellphone || !state.customer.cellphone.trim() || onlyNumber(state.customer.cellphone).length !== 11) && (input === 'customer_cellphone' || !input)) {
        invalid.customer_cellphone = true
      }
      if ((!state.customer.cep || !state.customer.cep.trim() || onlyNumber(state.customer.cep).length !== 8) && (input === 'customer_cep' || !input)) {
        invalid.customer_cep = true
      }
      if ((!state.customer.address || !state.customer.address.trim() || state.customer.address.length < 10 || state.customer.address.length > 200) && (input === 'customer_address' || !input)) {
        invalid.customer_address = true
      }
      if ((!state.customer.number || !state.customer.number.trim() || state.customer.number.length > 10) && (input === 'customer_number' || !input)) {
        invalid.customer_number = true
      }
      if ((!state.customer.neighborhood || !state.customer.neighborhood.trim() || state.customer.neighborhood.length < 5 || state.customer.neighborhood.length > 60) && (input === 'customer_neighborhood' || !input)) {
        invalid.customer_neighborhood = true
      }
      if (state.customer.complement.length > 100 && (input === 'customer_complement' || !input)) {
        invalid.customer_complement = true
      }
      if (!state.customer.state && (input === 'customer_state' || !input)) {
        invalid.customer_state = true
      }
      if (!state.customer.city && (input === 'customer_city' || !input)) {
        invalid.customer_city = true
      }
    }

    //Valida os campos de recuperação do carrinho
    if ([6].includes(level)) {
        if ((!state.customer.name || !state.customer.name.trim() || state.customer.name.length < 3) && (input === 'customer_name' || !input)) {
          invalid.customer_name = true
        }
        if (!validator.isEmail(state.customer.email) && (input === 'customer_email' || !input)) {
          invalid.customer_email = true
        }
        if ((!state.customer.cellphone || !state.customer.cellphone.trim() || onlyNumber(state.customer.cellphone).length !== 11) && (input === 'customer_cellphone' || !input)) {
          invalid.customer_cellphone = true
        }
    }

     //Valida o valor para pagamento com pix e cartao de credito
    if ([4, 5].includes(level) && state.payment_by === 5) {
        if (!state.value_pix || state.value_pix <= 0 || state.value_pix === '' || state.value_credit_card <= 0 || state.value_pix >= state.value || state.value_credit_card >= state.value) {
            invalid.value_pix = true
            console.log(state.value_pix, state.value)
            if (state.value_pix.value > state.value) {
                state.msg_value_pix = 'O valor do PIX não pode exceder o valor total da compra';
            }
            if (state.value_pix.value == state.value) {
                state.msg_value_pix = 'O valor do PIX não pode ser igual ao valor total da compra';
            }
            if (state.value_credit_card.value < 0) {
                state.msg_value_pix = 'O valor restante do cartão de crédito não pode ser negativo';
            }
            if (state.value_credit_card.value == 0) {
                state.msg_value_pix = 'O valor restante do cartão de crédito não pode ser zero';
            }
            if (state.value_credit_card.value >= state.value) {
                state.msg_value_pix = 'O valor restante do cartão de crédito não pode ser igual ou maior que o valor total da compra';
            }
            if (state.value_pix.value <= 0) {
                state.msg_value_pix = 'O valor do PIX deve ser maior que zero';
            }
        } else {
            state.msg_value_pix = '';
        }
    }

    setValidation(invalid)

    if (Object.keys(invalid).length > 0) {
      return false
    }

    return true
  }

  const handleFormData = () => {
    return {
      ...state,
      quantity_installments: state.type_payment === 1 ? state.quantity_installments.value : null,
      value: !state.fixed_value ? state.value : valueWithInterestOnOriginalValueWithBumpsAndDiscounts,
      card: {
        token: state.card.token,
        brand: state.card.bandeira,
        name: state.card.name,
        month: onlyNumber(state.card.month),
        year: onlyNumber(state.card.year),
        cvv: onlyNumber(state.card.cvv),
        cpfcnpj: onlyNumber(state.card.cpfcnpj)
      },
      customer: {
        ...state.customer,
        cpfcnpj: onlyNumber(state.customer.cpfcnpj),
        cep: onlyNumber(state.customer.cep),
        phone: onlyNumber(state.customer.phone),
        cellphone: onlyNumber(state.customer.cellphone),
        state_id: state.customer.state?.value,
        city_id: state.customer.city?.value,
      }
    }
  }

  const handleCartRecovery = async () => {
    if (!handleValidate(6)) {
      ErrorToast({ placeholder: 'Preencha todos os campos obrigatórios antes de continuar' })
      return
    }

    try {
        setLoading(true)
        let response = await api.post(`c/${state.id}/cart_recovery`, handleFormData())
        setState({
          ...state,
          cart_recovery_id: response.data.id
        })
        setShowCartRecovery(false)
    } catch (e) {
        console.log(e)
        let msg = e.errors ? e.errors.join('<br />') : 'Ocorreu um erro interno ao tentar salvar os dados, tente novamente mais tarde!'
        ErrorToast({ placeholder: msg })
    } finally {
        setLoading(false)
    }
  }

  const handleSubmit = async () => {
    if (!handleValidate(5)) {
      ErrorToast({ placeholder: 'Preencha todos os campos obrigatórios antes de continuar' })
      return
    }

    try {
      setLoading(true)
      let response = await api.post(`c/${state.id}/charge`, handleFormData())
      setCharge({...response.data})
    } catch (e) {
      console.log(e)
      let msg = e.errors ? e.errors.join('<br />') : 'Ocorreu um erro interno ao tentar gerar a cobrança, tente novamente mais tarde!'
      ErrorToast({ placeholder: msg })
    } finally {
      setLoading(false)
    }
  }

  const handleViewPdf = () => {
    if (!charge) {
      return
    }

    if (!charge.charges || state.type_payment !== 2) {
      window.open(charge.pdf_url);
    } else if (state.type_payment === 2) {
      window.open(charge.charges[0].pdf_url);
    }
  }

  const getData = async () => {
    try {
      setIsLoading(true)
      let response = await api.get(`c/${id}`)

      setOriginalValue(response.data.value);
      setState({
        ...state,
        ...response.data,
        max_quantity_installments: response.data.quantity_installments,
        quantity_installments: optionsInstallments[1],
        payment_by: response.data.payment_by_invoice === 1 ? 1 :
                    response.data.payment_by_card === 2 ? 2 : 4,
      })
    } catch (e) {
      console.log(e)
      let msg = e.errors ? e.errors.join('<br />') : 'Ocorreu um erro interno ao buscar as informações do link de pagamento, tente novamente mais tarde'
      ErrorToast({ placeholder: msg })
      handleBack()
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (typeof id !== 'undefined') {
      getData()
    }
  }, [id])

  const scriptGoogleAds = `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', '${state.googleAds}');
  `;

  const scriptMetaAds = `
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '${state.metaAds}');
  fbq('track', 'PageView');
  `;

  const scriptGoogleAnalytics = `
  // código do Google Analytics aqui
  `;

  const handleScripts = () => {
      const scriptGoogleAdsTag = document.createElement('script');
      scriptGoogleAdsTag.async = true;
      scriptGoogleAdsTag.src = `https://www.googletagmanager.com/gtag/js?id=${state.googleAds}`;

      const scriptGoogleAdsScript = document.createElement('script');
      scriptGoogleAdsScript.textContent = scriptGoogleAds;

      const scriptMetaAdsScript = document.createElement('script');
      scriptMetaAdsScript.textContent = scriptMetaAds;

      const noscriptMetaAds = document.createElement('noscript');
      const imgMetaAds = document.createElement('img');
      imgMetaAds.height = 1;
      imgMetaAds.width = 1;
      imgMetaAds.style.display = 'none';
      imgMetaAds.src = `https://www.facebook.com/tr?id=${state.metaAds}&ev=PageView&noscript=1`;
      imgMetaAds.alt = 'Facebook Pixel';
      noscriptMetaAds.appendChild(imgMetaAds);

      const scriptGoogleAnalyticsScript = document.createElement('script');
      scriptGoogleAnalyticsScript.textContent = scriptGoogleAnalytics;

      const scriptsToAdd = [
          scriptGoogleAdsTag,
          scriptGoogleAdsScript,
          scriptMetaAdsScript,
          noscriptMetaAds,
          scriptGoogleAnalyticsScript,
      ];

      const newScripts = scriptsToAdd.filter(script => !scripts.some(s => s.src === script.src || s.textContent === script.textContent));

      newScripts.forEach(script => {
          document.head.appendChild(script);
          setScripts(prevScripts => [...prevScripts, script]);
      });
  };

  const handleUpdatePixSituation = (situation) => {
    let oldCharge = charge;

    if (oldCharge?.charges && oldCharge.charges.length > 0 && oldCharge.charges[0].pix.id && oldCharge.charges[0].pix.situation !== situation) {
        oldCharge.charges[0].pix.situation = situation;
        setCharge({ ...oldCharge });
    } else if (oldCharge?.pix?.id && oldCharge.pix.situation !== situation) {
        oldCharge.pix.situation = situation;
        setCharge({ ...oldCharge });
    } else if (oldCharge?.pixCharge?.id && oldCharge.pixCharge.situation !== situation) {
        oldCharge.pixCharge.situation = situation;
        setCharge({ ...oldCharge });
    }

    if(interval) {
        clearInterval(interval);
    }

    if(situation === 'CONCLUIDA' && state.url) {
      interval = setInterval(() => {
        if(timeleftToRedirect > 0) {
            setTimeleftToRedirect(timeleftToRedirect - 1);
        }

        if(timeleftToRedirect === 0 ) {
          window.location.href = state.url;
        }
      }, 1000);
    }
};

  const handleGenerateInstallments = () => {
    const totalBumpAmount = bumpAmounts.reduce((acc, curr) => acc + curr, 0);
    const discountAmount = discountPercentage;

    let totalValue = originalValue - discountAmount;
    let installments = parseInt((originalValue / 15))
    let newOptions = {};

    if (installments > 1 && state.max_quantity_installments > 1) {
      installments = installments > state.max_quantity_installments ? state.max_quantity_installments : installments
      for (let i = 1; i <= installments; i++) {
        let value = (totalValue / i).toFixed(2)
        let bumpAmountRelative = (totalBumpAmount / i)

        value = value - (value * i - totalValue).toFixed(2)

        if (state.interest && i > 1) {
          let interestRate = parseFloat(state.interest_rate) / 100
          value = (value * (1 + interestRate) + bumpAmountRelative).toFixed(2)
        }
        let label = i === 1 ? `À vista (${formatValue({ value: totalValue + totalBumpAmount, currency: 'BRL' })})` : `${i} parcelas de ${formatValue({ value, currency: 'BRL' })}`
        if (state.interest && i > 1) {
          label += ` (Com Juros)`
        }
        let option = {
          value: i,
          label: label
        }
        newOptions[i] = option
      }
    } else {
      newOptions[1] = {
        value: 1,
        label: `À vista (${formatValue({ value: totalValue + totalBumpAmount, currency: 'BRL' })})`
      }
    }

    setOptionsInstallments(newOptions)
    setState((prevState) => ({
        ...prevState,
        quantity_installments: newOptions[prevState.quantity_installments?.value || 1],
    }));
}

    useEffect(() => {
        handleGenerateInstallments()
    }, [bumpAmounts, discountPercentage, state.payment_by])


  useEffect(() => {
    if (state.customer && onlyNumber(state.customer.cep).length === 8) {
      setLoading(true)
      buscaCep(state.customer.cep)
      .then(response => {
        setState({ ...state, customer: {
          ...state.customer,
          ...response,
          number: '',
          complement: '',
          city: {
            value: response.city_id,
            label: response.city
          },
          state: {
            value: response.state_id,
            label: response.uf
          },
        } })
      })
      .catch(e => {
        console.log(e)
      })
      .finally(_ => {
        setLoading(false)
      })
    }
  }, [updateAddress])

  return (
    <>
      <Row className="payment-link-grid" style={{ margin: isMobile() ? '0 -15px' : '32px 64px' }}>
        {
          !charge &&
          <>
            <Col md={8} sm={12} xs={12} className="mx-auto mb-2">
              {
                isLoading
                ?
                <ModalLoading />
                :
                <div className="modal-content" style={{ marginTop: 0, paddingTop: 0 }}>
                    <div className="modal-body" style={{ marginTop: 0, paddingTop: 0 }}>
                    {/* GOOGLE ADS */}
                    {(state.googleAds || state.scriptGoogleAnalytics || state.metaAds) && (
                        handleScripts()
                    )}
                    {/* BANNER */}
                    <Row>
                        {
                        state.payment_link_images.length > 0 &&
                            <Col md={12} sm={12} xs={12} style={{ margin: 0, padding: 0}}>
                                <img src={state.payment_link_images[0]?.url} style={{ width: '100%', margin: 0 }} alt="Banner" />
                            </Col>
                        }
                    </Row>
                    {/* EXPIRADO OU INATIVO */}
                    {
                       (state.due_date && formatDate({ value: state.due_date, location: 'En', format: 'Date'}) < formatDate({ value: new Date(), location: 'En', format: 'Date'})) ||
                       (!state.active) ? (
                         <Row>
                           <Col md={12} sm={12} xs={12} style={{ margin: 20, padding: 20, color: 'red', fontWeight: 'bold', fontSize: 24, textAlign: 'center'}}>
                             {state.due_date && formatDate({ value: state.due_date, location: 'En', format: 'Date'}) < formatDate({ value: new Date(), location: 'En', format: 'Date'}) ? (
                               'Link de Pagamento Expirado'
                             ) : (
                               'Link de Pagamento Inativo'
                             )}
                           </Col>
                         </Row>
                       ) : null
                    }
                    {
                        ((showCartRecovery && !state.due_date && state.active) || (showCartRecovery && state.due_date && formatDate({ value: state.due_date, location: 'En', format: 'Date'}) >= formatDate({ value: new Date(), location: 'En', format: 'Date'}) && state.active )) && (
                        <Col md={12} style={{ padding: '0' }}>
                            <CardHeader className="mt-4">
                                <CardTitle>Preencha as Seguintes Informações</CardTitle>
                            </CardHeader>
                            <CardBody className='d-flex flex-column align-items-center'>
                                <Col md={8} sm={12} xs={12}>
                                <FormGroup>
                                    <Input type="text" placeholder="Nome completo" value={state.customer.name} onChange={({ target: { value } }) => handleChangeCustomer(value, 'name')} invalid={validation.customer_name} onBlur={() => handleValidate(4, 'customer_name')} />
                                    <FormFeedback>Informe um nome válido</FormFeedback>
                                    </FormGroup>
                                    </Col>
                                    <Col md={8} sm={12} xs={12}>
                                    <FormGroup>
                                        <InputMask invalid={validation.customer_cellphone} mask="(99) 99999-9999" placeholder="Celular" maskChar={null} value={state.customer.cellphone} onChange={({ target: { value } }) => handleChangeCustomer(value, 'cellphone')} onBlur={() => handleValidate(4, 'customer_cellphone')} />
                                        <FormFeedback>Informe um celular válido</FormFeedback>
                                    </FormGroup>
                                    </Col>
                                    <Col md={8} sm={12} xs={12}>
                                    <FormGroup>
                                        <Input invalid={validation.customer_email} type="text" placeholder="E-mail" value={state.customer.email} onChange={({ target: { value } }) => handleChangeCustomer(value, 'email')} onBlur={() => handleValidate(4, 'customer_email')} />
                                        <FormFeedback>Informe um e-mail válido</FormFeedback>
                                    </FormGroup>
                                    </Col>
                            </CardBody>
                            <Button className='float-right' color="info" onClick={handleCartRecovery}>Prosseguir</Button>
                        </Col>
                    )}
                    {/* SE NAO ESTIVER EXPIRADO OU INATIVO */}
                    {/* IMAGENS SECUNDARIAS */}
                    {
                        ((!showCartRecovery && !state.due_date && state.active) || (!showCartRecovery && state.due_date && formatDate({ value: state.due_date, location: 'En', format: 'Date'}) >= formatDate({ value: new Date(), location: 'En', format: 'Date'}) && state.active )) && (
                    <Row>
                        <Col md={8} sm={12} xs={12} style={{ marginTop: 10, padding: '10' }}>
                        {
                            state.company &&
                            <Col md={12} sm={12} xs={12} style={{ padding: '0', display: 'flex', alignItems: 'center' }}>
                            {
                                state.company.company_settings?.custom_img_url &&
                                <img src={state.company.company_settings.custom_img_url} style={{ maxWidth: 60, height: 60, marginRight: 10  }} alt="Logo" />
                            }
                                 <div style={{ flex: 1 }}>
                                    <h6 className="modal-title text-left"><b>{state.company.name}</b></h6>
                                    <span className="modal-description text-left">{formatCpfCnpj({ value: state.company.cpfcnpj })}</span><br />
                                    <span>{state.name}</span><br />
                                    <span>{state.description}</span>
                                        {
                                        state.fixed_value &&
                                        <>
                                            <br />
                                            <span className="text-dark d-flex"><h6 className="mr-1"><b>{formatValue({ value: state.value, currency: 'BRL' })}</b></h6> {state.type_payment === 2 && <span className="text-secondary">({optionsCycle[state.cycle].label.toLowerCase()})</span>}</span>
                                        </>
                                        }
                                </div>
                            </Col>
                        }
                        <>
                        {
                        <>
                        <h6 className="mt-4 mb-3"><b>Dados do comprador</b></h6>
                        <Row>
                            <Col md={12} sm={12} xs={12}>
                            <FormGroup>
                                <Input type="text" placeholder="Nome completo" value={state.customer.name} onChange={({ target: { value } }) => handleChangeCustomer(value, 'name')} invalid={validation.customer_name} onBlur={() => handleValidate(4, 'customer_name')} />
                                <FormFeedback>Informe um nome válido</FormFeedback>
                            </FormGroup>
                            </Col>
                            <Col md={12} sm={12} xs={12}>
                            <FormGroup>
                              <Input
                                type="text"
                                placeholder={
                                  state.cpf_cnpj === 1 ? 'CPF' : state.cpf_cnpj === 2 ? 'CNPJ' : 'CPF ou CNPJ'
                                }
                                value={formatCpfCnpj({ value: state.customer.cpfcnpj })}
                                onChange={({ target: { value } }) => handleChangeCustomer(value, 'cpfcnpj')}
                                invalid={validation.customer_cpfcnpj}
                                onBlur={() => handleValidate(4, 'customer_cpfcnpj')}
                                maxLength={
                                  state.cpf_cnpj === 1 ? 14 : state.cpf_cnpj === 2 ? 18 : undefined
                                }
                              />
                               <FormFeedback>
                                {state.cpf_cnpj === 1
                                ? 'Informe um CPF válido'
                                : state.cpf_cnpj === 2
                                ? 'Informe um CNPJ válido'
                                : 'Informe um CPF ou CNPJ válido'}
                            </FormFeedback>
                            </FormGroup>
                            </Col>
                            <Col md={6} sm={12} xs={12}>
                            <FormGroup>
                                <InputMask invalid={validation.customer_cellphone} mask="(99) 99999-9999" placeholder="Celular" maskChar={null} value={state.customer.cellphone} onChange={({ target: { value } }) => handleChangeCustomer(value, 'cellphone')} onBlur={() => handleValidate(4, 'customer_cellphone')} />
                                <FormFeedback>Informe um celular válido</FormFeedback>
                            </FormGroup>
                            </Col>
                            <Col md={6} sm={12} xs={12}>
                            <FormGroup>
                                <InputMask mask="(99) 9999-9999" placeholder="Telefone (opcional)" maskChar={null} value={state.customer.phone} onChange={({ target: { value } }) => handleChangeCustomer(value, 'phone')} />
                            </FormGroup>
                            </Col>
                            <Col md={12} sm={12} xs={12}>
                            <FormGroup>
                                <Input invalid={validation.customer_email} type="text" placeholder="E-mail" value={state.customer.email} onChange={({ target: { value } }) => handleChangeCustomer(value, 'email')} onBlur={() => handleValidate(4, 'customer_email')} />
                                <FormFeedback>Informe um e-mail válido</FormFeedback>
                            </FormGroup>
                            </Col>
                        </Row>
                        <h6 className="mt-4 mb-3"><b>Endereço de cobrança</b></h6>
                        <Row>
                            <Col md={6} sm={12} xs={12}>
                            <FormGroup>
                                <InputMask invalid={validation.customer_cep} mask="99999-999" placeholder="CEP" maskChar={null} value={state.customer.cep} onChange={({ target: { value } }) => handleChangeCustomer(value, 'cep')} onBlur={() => handleValidate(4, 'customer_cep')} />
                                <FormFeedback>Informe um CEP válido</FormFeedback>
                            </FormGroup>
                            </Col>
                            <Col md={6} sm={12} xs={12}>
                            <FormGroup>
                                <Input invalid={validation.customer_address} type="text" placeholder="Logradouro" value={state.customer.address} onChange={({ target: { value } }) => handleChangeCustomer(value, 'address')} onBlur={() => handleValidate(4, 'customer_address')} />
                                <FormFeedback>Informe um logradouro válido</FormFeedback>
                            </FormGroup>
                            </Col>
                            <Col md={6} sm={12} xs={12}>
                            <FormGroup>
                                <Input invalid={validation.customer_number} type="text" placeholder="Número" maxLength={10} value={state.customer.number} onChange={({ target: { value } }) => handleChangeCustomer(value, 'number')} onBlur={() => handleValidate(4, 'customer_number')} />
                                <FormFeedback>Informe um número válido</FormFeedback>
                            </FormGroup>
                            </Col>

                            <Col md={6} sm={12} xs={12}>
                            <FormGroup>
                                <Input invalid={validation.customer_neighborhood} type="text" placeholder="Bairro" value={state.customer.neighborhood} onChange={({ target: { value } }) => handleChangeCustomer(value, 'neighborhood')} onBlur={() => handleValidate(4, 'customer_neighborhood')} />
                                <FormFeedback>Informe um bairro válido</FormFeedback>
                            </FormGroup>
                            </Col>
                            <Col md={12} sm={12} xs={12}>
                            <FormGroup>
                                <Input invalid={validation.customer_complement} type="text" placeholder="Complemento" value={state.customer.complement} onChange={({ target: { value } }) => handleChangeCustomer(value, 'complement')} onBlur={() => handleValidate(4, 'customer_complement')} />
                                <FormFeedback>O complemento pode ter no máximo 100 caracteres</FormFeedback>
                            </FormGroup>
                            </Col>
                            <Col md={6} sm={12} xs={12}>
                            <FormGroup>
                                <AutoComplete invalid={validation.customer_city} invalidMsg="Selecione uma cidade válida" urlFind="cities" placeholder="Cidade" defaultOptions={true} value={state.customer.city} onChange={value => handleChangeCustomer(value, 'city')} onBlur={() => handleValidate(4, 'customer_city')} aditionalFilters={{
                                state_id: state.customer.state ? state.customer.state.value : ''
                                }} />
                                <FormFeedback>Selecione uma cidade válida</FormFeedback>
                            </FormGroup>
                            </Col>
                            <Col md={6} sm={12} xs={12}>
                            <FormGroup>
                                <AutoComplete invalid={validation.customer_state} invalidMsg="Selecione um estado válido" urlFind="states" optionLabel="sigla" placeholder="UF" defaultOptions={true} value={state.customer.state} onChange={value => handleChangeCustomer(value, 'state')} onBlur={() => handleValidate(4, 'customer_state')} />
                                <FormFeedback>Selecione um estado válido</FormFeedback>
                            </FormGroup>
                            </Col>
                        </Row>
                        </>
                    }
                    {/* FORMAS DE PAGAMENTO */}
                    {
                        (state.payment_by_card || state.payment_by_invoice || state.payment_by_pix) &&
                        <>
                        <Row>
                            <Col md={4} sm={12} xs={12}>
                              <Card className={`card-type-payment ${state.payment_by_card && selectedCard === 'cartao' && 'is-checked'} ${!state.payment_by_card && 'disabled'}`} title={state.payment_by_card ? '' : 'Opção não disponível para esta compra'} onClick={(e) => {if (!state.payment_by_card) {e.preventDefault();} else {setSelectedCard('cartao'); setState({ ...state, payment_by: 2, payment_by_pix_and_card: false, value_credit_card: '', value_pix: '', });}}} style={{ cursor: !state.payment_by_card ? 'not-allowed' : 'pointer' }}>
                              <CardHeader className={`${selectedCard === 'cartao' ? 'text-focus' : 'text-dark'}`}>
                                <div className="flex-space-between justify-content-center">
                                  <div className="d-flex align-items-center">
                                      <FaCreditCard size={24} className="mr-2" />
                                      <span className="mr-2">
                                      Cartão
                                      </span>
                                  </div>
                                  {selectedCard === 'cartao' && (
                                    <div className="d-flex align-items-center justify-content-end">
                                    <FaCheck size={18} className="ml-2 text-success" />
                                    </div>
                                    )
                                  }
                                </div>
                              </CardHeader>
                              </Card>
                              <br />
                            </Col>
                            <Col md={4} sm={12} xs={12}>
                              <Card className={`card-type-payment ${state.payment_by_invoice && selectedCard === 'boleto' && 'is-checked'} ${!state.payment_by_invoice && 'disabled'}`} title={state.payment_by_invoice ? '' : 'Opção não disponível para esta compra'} onClick={(e) => {if (!state.payment_by_invoice) {e.preventDefault();} else {setSelectedCard('boleto'); setState({ ...state, payment_by: 1, payment_by_pix_and_card: false, value_credit_card: '', value_pix: '', quantity_installments: { value: 1, label: `À vista (${formatValue({ value: state.value, currency: 'BRL' })})` }});}}}  style={{ cursor: !state.payment_by_invoice ? 'not-allowed' : 'pointer' }}>
                              <CardHeader className={`${selectedCard === 'boleto' ? 'text-focus' : 'text-dark'}`}>
                                <div className="flex-space-between justify-content-center">
                                  <div className="d-flex align-items-center">
                                    <FaBarcode size={24} className="mr-2" />
                                    <span className="mr-2 align-items-center">
                                    Boleto
                                    </span>
                                  </div>
                                  {selectedCard === 'boleto' && (
                                    <div className="d-flex align-items-center justify-content-end">
                                      <FaCheck size={18} className="ml-2 text-success" />
                                    </div>
                                  )}
                                </div>
                              </CardHeader>
                              </Card>
                              <br />
                            </Col>
                            <Col md={4} sm={12} xs={12}>
                              <Card className={`card-type-payment ${state.payment_by_pix && selectedCard === 'pix' && 'is-checked'} ${!state.payment_by_pix && 'disabled'}`} title={state.payment_by_pix ? '' : 'Opção não disponível para esta compra'} onClick={(e) => {if (!state.payment_by_pix) {e.preventDefault();} else {setSelectedCard('pix'); setState({ ...state, payment_by: 4, payment_by_pix_and_card: false, value_credit_card: '', value_pix: '', quantity_installments: { value: 1, label: `À vista (${formatValue({ value: state.value, currency: 'BRL' })})` }});}}} style={{ cursor: !state.payment_by_pix ? 'not-allowed' : 'pointer' }}>
                              <CardHeader className={` ${selectedCard === 'pix' ? 'text-focus' : 'text-dark'}`}>
                                <div className="flex-space-between justify-content-center">
                                    <div className="d-flex align-items-center">
                                        <FaPix size={24} className="mr-2" />
                                        <span className="mr-2 text-center">
                                        PIX
                                        </span>
                                    </div>
                                    {selectedCard === 'pix' && (
                                    <div className="d-flex align-items-center justify-content-end">
                                        <FaCheck size={18} className="ml-2 text-success" />
                                    </div>
                                    )}
                                </div>
                              </CardHeader>
                              </Card>
                              <br />
                            </Col>
                        </Row>
                        {state.hybrid_payment &&
                        (
                            <Col md={12} sm={12} xs={12} className="text-left">
                            <CustomInput type="checkbox" id="payment_by_pix_and_card" label="Pagar com PIX e Cartão de Crédito"
                              checked={state.payment_by_pix_and_card}
                              onChange={({ target: { checked } }) => {
                                setState({ ...state, payment_by_pix_and_card: checked, payment_by: 5, quantity_installments: { value: 1, label: `À vista (${formatValue({ value: state.value, currency: 'BRL' })})` }}); setSelectedCard('payment_by_pix_and_card');}}
                                disabled={!state.payment_by_pix && !state.payment_by_card && !state.payment_by_invoice} />
                            <br />
                            </Col>
                        )
                        }
                        </>
                    }
                    {state.hybrid_payment && state.payment_by_pix_and_card && (
                    <Col md={12} sm={12} xs={12} className="text-left">
                        <h6>Pagamento Pix + Cartão de Crédito</h6>
                        <Label htmlFor="value_pix">Valor do PIX <span className="text-danger">*</span></Label>
                        <FormGroup>
                        <InputCurrency id="value_pix" invalid={validation.value_pix} invalidMsg={state.msg_value_pix} placeholder='0,00' prefix="R$" precision={12} value={state.value_pix} onChange={value => handleChange(formatFloat({ value }), 'value_pix')} onBlur={() => handleValidate(4,'value_pix')} />
                            <span>Valor restante para Cartão de Crédito: {formatValue({ value: (state.value_credit_card || 0), currency: 'BRL' })}</span>
                            <br />
                            <span>Valor Total da Compra: {formatValue({ value: valueWithInterestOnOriginalValueWithBumpsAndDiscounts, currency: 'BRL' })}</span>
                        <FormFeedback>Informe um valor válido</FormFeedback>
                        </FormGroup>
                        <br />
                    </Col>
                    )}
                    {(selectedCard === 'cartao' || state.payment_by_pix_and_card) && (
                    <>
                        {!state.payment_by_pix_and_card ? <h5>Pagamento com cartão de crédito</h5> : <h6>Pagamento restante com cartão crédito</h6>}
                        <span>Bandeiras aceitas: </span>
                        <br />
                        {
                            CardIcons.map((icon, key) => {
                            return <img key={key} className='px-2' src={icon.src} alt={`card-icon-${key}`} style={{ width: 50, height: 50 }} />
                            })
                        }
                        <Row className="mt-4">
                            <Col md={12} sm={12} xs={12}>
                            <Row>
                                <Col md={12}>
                                <FormGroup>
                                    <Input type="text" placeholder="CPF ou CNPJ do titular do cartão" value={formatCpfCnpj({ value: state.card.cpfcnpj })} onChange={({ target: { value } }) => handleChangeCard(value, 'cpfcnpj')} invalid={validation.card_cpfcnpj} onBlur={() => handleValidate(4, 'card_cpfcnpj')} />
                                    <FormFeedback>Informe um CPF ou CNPJ válido</FormFeedback>
                                </FormGroup>
                                </Col>
                                <Col md={12}>
                                <FormGroup>
                                    <CardInputToken
                                        invalid={validation.card_number}
                                        mask={cardsMasks(state.card.number)}
                                        credencial={state.company?.pjbank_credit_card_credential?.credencial}
                                        sandbox={window.location.href.includes('dev') || window.location.href.includes('sandbox') ? true : false}
                                        maskChar={null}
                                        placeholder="Número do cartão"
                                        value={state.card.number}
                                        onChange={({ target: { value } }) => handleChangeCard(value, 'number')}
                                        onBlur={() => handleValidate(4, 'card_number')}
                                        dataCallback={({ token, bandeira }) => setState({ ...state, card: { ...state.card, token, bandeira } })}
                                    />
                                    <FormFeedback>Informe um número de cartão válido</FormFeedback>
                                </FormGroup>
                                </Col>
                                <Col md={12}>
                                <FormGroup>
                                    <Input invalid={validation.card_name} type="text" className="form-control" placeholder="Nome do titular do cartão" value={state.card.name} onChange={({ target: { value } }) => handleChangeCard(value, 'name')} onBlur={() => handleValidate(4, 'card_name')} />
                                    <FormFeedback>Informe um nome válido</FormFeedback>
                                </FormGroup>
                                </Col>
                                <Col md={12}>
                                <FormGroup>
                                    <InputMask invalid={validation.card_cvv} mask="9999" maskChar={null} placeholder="Código de segurança" value={state.card.cvv} onChange={({ target: { value } }) => handleChangeCard(value, 'cvv')} onBlur={() => handleValidate(4, 'card_cvv')} />
                                    <FormFeedback>Informe um código válido</FormFeedback>
                                </FormGroup>
                                </Col>
                                <Col md={12}>
                                <p>Válido até:</p>
                                </Col>
                                <Col md={6} sm={6} xs={12}>
                                <FormGroup>
                                    <InputMask invalid={validation.card_month} mask="99" maskChar={null} placeholder="Mês" value={state.card.month} onChange={({ target: { value } }) => handleChangeCard(value, 'month')} onBlur={() => handleValidate(4, 'card_month')} />
                                    <FormFeedback>Informe um mês válido</FormFeedback>
                                </FormGroup>
                                </Col>
                                <Col md={6} sm={6} xs={12}>
                                <FormGroup>
                                    <InputMask invalid={validation.card_year} mask="9999" maskChar={null} placeholder="Ano" value={state.card.year} onChange={({ target: { value } }) => handleChangeCard(value, 'year')} onBlur={() => handleValidate(4, 'card_year')} />
                                    <FormFeedback>Informe um ano válido</FormFeedback>
                                </FormGroup>
                                </Col>
                            </Row>
                            </Col>
                        </Row>
                        </>
                    )
                    }
                    {/* CUPOM */}
                    {
                        (state.payment_by_invoice || state.payment_by_card || state.payment_by_pix) && state.fixed_value &&
                        <FormGroup>
                          <Label for="coupon_code">Código do Cupom</Label>
                          <InputGroup>
                            <Input
                              type="text"
                              maxLength={100}
                              value={coupon_code}
                              onChange={({ target: { value } }) => setCouponCode(value.trim())}
                              placeholder="Digite o código do cupom"
                              onBlur={() => handleValidate('coupon_code')}
                              disabled={isValidCoupon}
                            />
                            <InputGroupAddon addonType="append">
                                <Button
                                    color={isValidCoupon ? 'success' : 'info'}
                                    title={
                                        !coupon_code ? 'Digite um código de cupom' :
                                        isValidCoupon ? 'Cupom aplicado' : 'Clique para validar o cupom'
                                    }
                                    disabled={!coupon_code}
                                    onClick={() => validateCouponCode()}
                                >
                                    {isValidCoupon ? <FaCheck /> : <FaUnlock />}
                                </Button>
                            </InputGroupAddon>
                          </InputGroup>
                            <FormGroup style={{ display: 'flex', justifyContent: 'flex-end' }}>
                              {isValidCoupon && (
                                  <Button
                                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                                    color="link"
                                    onClick={() => {
                                    removeDiscount();
                                }}
                                >
                                    Remover Cupom
                                </Button>
                                )}
                            </FormGroup>
                        </FormGroup>
                    }
                    {/* DESCRICAO */}
                    <Row>
                        {
                            state.description &&
                            <Col md={12} sm={12} xs={12}>
                            <h6 className="text-info">{state.name}</h6>
                            <span>{state.description}</span>
                        </Col>
                        }
                    {/* PARCELAS CARTAO E VALOR*/}
                        <Col md={6} xs={12} sm={12}>
                        {
                            selectedCard === 'cartao' && state.max_quantity_installments > 1 &&
                            <FormGroup className='mt-3 mb-0 pb-0'>
                            <Label className="text-dark font-weight-bold mt-2">Parcelas</Label>
                            <ReactSelect placeholder="Selecione a parcela..." value={state.quantity_installments} onChange={value => handleChange(value, 'quantity_installments')} options={Object.values(optionsInstallments)} />
                            </FormGroup>
                        }
                        <FormGroup>
                            <Label className="text-secondary" for="value"> {(!state.fixed_value && state.type_payment === 2) && `(${optionsCycle[state.cycle].label.toLowerCase()})`}</Label>
                            {
                            state.fixed_value &&
                            <>
                                <br />
                                <span className="d-flex"><h5 className="mr-1">{formatValue({ value: valueWithInterestOnOriginalValueWithBumpsAndDiscounts, currency: 'BRL' })}</h5> {state.type_payment === 2 && <span className="text-secondary">({optionsCycle[state.cycle].label.toLowerCase()})</span>}</span>
                                {isValidCoupon &&  <span className="d-flex">Desconto aplicado: {formatValue({ value: discountPercentage, currency: 'BRL' })}</span>}
                            </>
                            }
                            {
                            !state.fixed_value &&
                            <>
                                <InputCurrency
                                    invalid={validation.value}
                                    invalidMsg={`O valor mínimo para pagamento no ${state.payment_by === 1 ? 'boleto bancário' : 'cartão de crédito ou pix'} é de R$ ${state.payment_by === 1 ? '15' : '15'},00`}
                                    placeholder='R$ 0,00'
                                    allowNegativeValue={false}
                                    prefix="R$ "
                                    precision={12}
                                    value={state.value}
                                    onChange={value => handleChange(formatFloat({ value }), 'value')}
                                    onBlur={() => handleValidate(4, 'value')}
                                />
                            </>
                            }
                        </FormGroup>
                        </Col>
                    </Row>
                    {
                        (state.payment_by_card) &&
                        (selectedCard === 'cartao' && state.payment_by_card) &&
                        <span className="text-muted" style={{ fontSize: '12px' }}>
                        <FaLock size={18} className="mr-2 text-muted" />
                        Nós protegemos seus dados de pagamento usando encriptação para prover segurança no nível de bancos
                        </span>
                    }
                    {/* ORDER BUMP */}
                    {
                        (state.payment_by_invoice || state.payment_by_card || state.payment_by_pix) &&
                        state.fixed_value && (state.payment_link_order_bumps && state.payment_link_order_bumps.length > 0) &&
                        (state.payment_link_order_bumps.filter(bump => bump.active).map((bump, key) => {
                            const checkboxId = `checkbox-${key}`;

                            return  <Card key={key} className="mt-2 card-type-payment is-dashed">
                            <CardBody>
                                <div className="d-flex justify-content-center align-items-center">
                                  <span className="mt-2 text-info font-weight-bold" style={{ fontSize: 20 }}>
                                    {bump.title}
                                  </span>
                                </div>
                              </CardBody>
                              <CardBody className="text-dark pt-0">
                                <div className="d-flex justify-content-start align-items-center">
                                  <div className="d-flex align-items-center">
                                    <span className="mr-2">
                                    <FaArrowRight size={26} style={{ color: 'red' }} />
                                    </span>
                                    <CustomInput
                                        type="checkbox"
                                        id={checkboxId}
                                        label=""
                                        className="mr-2 border-2 text-dark border-black"
                                        checked={state.checkedBumps.includes(bump.id)}
                                        onChange={(event) => {
                                            const newCheckedBumps = [...state.checkedBumps];
                                            if (newCheckedBumps.includes(bump.id)) {
                                              newCheckedBumps.splice(newCheckedBumps.indexOf(bump.id), 1);
                                              setTotalValue(prevTotalValue => (parseFloat(prevTotalValue) - parseFloat(bump.product_value)).toFixed(2));
                                            } else {
                                              newCheckedBumps.push(bump.id);
                                              setTotalValue(prevTotalValue => (parseFloat(prevTotalValue) + parseFloat(bump.product_value)).toFixed(2));
                                            }
                                            setState({ ...state, checkedBumps: newCheckedBumps });
                                            handleCheckboxChange(event, bump);
                                          }}
                                      />
                                  </div>
                                  <div>
                                    <span className="font-weight-bold" style={{ color: 'red', fontSize: 22 }}>
                                      {bump.product_name}
                                    </span>
                                    <br />
                                    <span className="text-muted font-weight-bold" style={{ fontSize: 18 }}>
                                      {bump.highlight_text} - {formatValue({ value: bump.product_value, currency: 'BRL' })}
                                    </span>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                        }))
                    }
                    {/* COMPRAR */}
                    {
                        (state.payment_by_invoice || state.payment_by_card || state.payment_by_pix || state.payment_by_pix_and_card) &&
                        ((selectedCard === 'boleto' && state.payment_by_invoice) ||
                        (selectedCard === 'cartao' && state.payment_by_card) ||
                        (selectedCard === 'pix' && state.payment_by_pix) || state.payment_by_pix_and_card) &&
                        <Button className="mt-2 mr-2 w-100" color="success" style={{ fontSize: '18px', height: '50px' }} onClick={handleSubmit}>Comprar Agora</Button>
                    }
                    </>
                    {/* VANTAGENS */}
                    {
                        (state.advantages_privacy || state.advantages_approved || state.advantages_purchase || state.advantages_email) &&
                        state.advantages_privacy &&
                        <Card className="mt-3 card-type-payment is-bordered">
                        <CardHeader className="text-dark">
                          <div className="d-flex justify-content-center align-items-center">
                            <FaLock size={24} className="mr-2 text-info" />
                            <div className="flex-column">
                              <span className="text-dark font-weight-bold" style={{ fontSize: 12 }}>
                                Privacidade
                              </span>
                              <br />
                              <span className="text-info" style={{ fontSize: 10 }}>
                                Sua informação 100% segura
                              </span>
                            </div>
                          </div>
                        </CardHeader>
                        </Card>
                    }
                    {
                        state.advantages_purchase &&
                        <Card className="mt-3 card-type-payment is-bordered">
                        <CardHeader className="text-dark">
                          <div className="d-flex justify-content-center align-items-center">
                            <FaShieldAlt size={24} className="mr-2 text-info" />
                            <div className="flex-column">
                              <span className="text-dark font-weight-bold" style={{ fontSize: 12 }}>
                                Compra Segura
                              </span>
                              <br />
                              <span className="text-info" style={{ fontSize: 10 }}>
                                Sua informação 100% segura
                              </span>
                            </div>
                          </div>
                        </CardHeader>
                        </Card>
                    }
                    {
                        state.advantages_email &&
                        <Card className="mt-3 card-type-payment is-bordered">
                        <CardHeader className="text-dark">
                          <div className="d-flex justify-content-center align-items-center">
                          {/* <i class="fal fa-envelope-open"></i> */}
                            <FaEnvelopeOpen size={24} className="mr-2 text-info" />
                            <div className="flex-column">
                              <span className="text-dark font-weight-bold" style={{ fontSize: 12 }}>
                                Entregue via E-mail
                              </span>
                              <br />
                              <span className="text-info" style={{ fontSize: 10 }}>
                                Acesso ao produto entregue por e-mail
                              </span>
                            </div>
                          </div>
                        </CardHeader>
                        </Card>
                    }
                    {
                        state.advantages_approved &&
                        <Card className="mt-3 card-type-payment is-bordered">
                        <CardHeader className="text-dark">
                          <div className="d-flex justify-content-center align-items-center">
                            <FaCheck size={24} className="mr-2 text-info" />
                            <div className="flex-column">
                              <span className="text-dark font-weight-bold" style={{ fontSize: 12 }}>
                                Conteúdo aprovado
                              </span>
                              <br />
                              <span className="text-info" style={{ fontSize: 10 }}>
                                100% revisado e aprovado
                              </span>
                            </div>
                          </div>
                        </CardHeader>
                        </Card>
                    }
                    {/* CONTRATO */}
                    {
                        state.contract_link?.length > 0 &&
                        <Card className="mt-3 card-type-payment is-bordered">
                        <div className="p-3 text-center" style={{ backgroundColor: '#F5F5F5', borderRadius: '5px'}}>
                            <h5 className="m-0">Contrato do Usuário - {state.company.name}</h5>
                            <div className="justify-content-center">
                            <br />
                            <a href={state.contract_link} target="_blank" rel="noopener noreferrer" className="text-decoration-none text-info">Clique aqui para acessar o Contrato</a>
                            </div>
                            <p className="mt-2 text-justify">{state.contract_text}</p>
                        </div>
                        </Card>
                    }
                    </Col>
                    {
                        <Col md={4} sm={12} xs={12} style={{ padding: '0', width: '100%'}}>
                        {
                            state.payment_link_images.length > 0 &&
                            <div className="image-grid" style={{ display: 'flex', flexDirection: 'column' }}>
                            {state.payment_link_images.map((img, key) => {
                            if (!img.main) {
                                return (
                                <img key={key} src={img.url} alt="Imagem" style={{ objectFit: 'contain', width: '100%' }} />
                                );
                            }
                            return null;
                            })}
                            </div>
                        }
                        </Col>
                    }
                </Row>
                )}
                </div>
                </div>
              }
            </Col>
          </>
        }
        {
          charge &&
          <>
            <Col className="mb-2">
              <div className="modal-content">
                <div className="d-flex justify-content-center align-items-center card float-barcode">
                  <FaBarcode size={20} color="var(--success)" />
                </div>
                <div className="modal-body bg-success text-white" style={{ height: 16, padding: 0 }} />
                <div className="modal-body" style={{ padding: '24px 74px' }}>
                {
                    charge && state.quantity_installments.value >= 1 && state.payment_by === 2 ? (
                        <h3>
                        <b>Tudo certo! Recebemos o seu pagamento de {formatValue({ value: charge.value, currency: 'BRL' })}</b>
                        <br />
                        {state.url && state.url.length > 0 && handleRedirect()}
                        {state.url && state.url.length > 0 && (
                        <p>Você será redirecionado em {timeleftToRedirect} segundos...</p>
                        )}
                        </h3>
                    ) : ((charge && charge.creditCardCharge && charge.creditCardCharge.status === 1) || (charge.charges && charge.charges[1]?.type_payment === 2)) ? (
                        <h3>
                        <b>Tudo certo! Recebemos o pagamento do cartão de crédito no valor de {formatValue({ value: charge.creditCardCharge?.value || charge.charges?.[1]?.value, currency: 'BRL' })}</b>
                        </h3>
                    ) : (
                        <h3>
                        <b>Tudo certo! Agora é só realizar o pagamento {!charge.charges ? `no valor de ${formatValue({ value: charge.value, currency: 'BRL' })}` : `da 1ª parcela no valor de ${formatValue({ value: charge.charges[0].value, currency: 'BRL' })}`}</b>
                        </h3>
                    )
                }
                  {
                    (charge.charges && state.type_payment === 1) &&
                    <span className="mt-4 text-secondary">Sua compra em {charge.charges.length} x de {formatValue({ value: charge.charges[0].value, currency: 'BRL' })} foi gerada e a 1ª parcela está disponível para pagamento. Para efetuar o pagamento das demais parcelas clique em "Visualizar Boletos" ou <Link to={`/i/${charge.charges[0].pub_identifier}`} target="_blank">acesse a sua fatura</Link></span>
                  }
                  {
                      (charge.charges && state.type_payment === 2 && !charge.charges[1]) &&
                      <>
                        <span className="mt-4 text-secondary">Sua assinatura com frequência {optionsCycle[state.cycle].label.toLowerCase()} de {formatValue({ value: charge.charges[0].value, currency: 'BRL' })} foi confirmada e a 1ª parcela está disponível para pagamento.</span>
                        <br />
                        <Link to={`/i/${charge.charges[0].pub_identifier}`} target="_blank">Acesse a sua fatura</Link>
                      </>
                  }
                  {
                      (charge.charges && state.type_payment === 2 && charge.charges[1]) &&
                      <>
                        <span className="mt-4 text-secondary">Sua assinatura com frequência {optionsCycle[state.cycle].label.toLowerCase()} foi confirmada.</span>
                        <br />
                        <br />
                        <span className="mt-4 text-secondary">Efetue o restante do pagamento de {formatValue({ value: charge.charges[0].value, currency: 'BRL' })} no PIX.</span>
                        <br />
                        {
                        charge.charges && charge.charges[1] && !charge.charges[0].pix &&
                        <p className="mt-5">
                            <Link to={`/i/${!charge.charges ? charge.pub_identifier : charge.charges[0].pub_identifier}`} target="_blank">Visualize a fatura </Link>
                            e tenha acesso a todas as informações do PIX.
                        </p>
                        }
                      </>
                  }
                  {
                    charge.plug_boleto &&
                    <>
                        <h5 className="mt-4 mb-4">Para pagar com Boleto Bancário</h5>
                        <span className="mt-3">Copie a linha digitável {(!charge.charges || state.type_payment === 2) ? 'ou visualize seu boleto' : 'da 1ª parcela ou visualize os boletos deste parcelamento'}:</span>
                        <br />
                        <span className="text-secondary">{!charge.charges ? charge.plug_boleto.digitable_line : charge.charges[0].plug_boleto.digitable_line}</span>
                        <br />
                        <CopyToClipboard onCopy={() => SuccessToast({ placeholder: 'Linha digitável copiada para área de transferência!' })} text={!charge.charges ? charge.plug_boleto.digitable_line : charge.charges[0].plug_boleto.digitable_line}>
                            <Button className="mt-4 mr-2" color="success">Copiar Linha Digitável</Button>
                        </CopyToClipboard>
                        <Button className="mt-4 mr-2" color="success" outline onClick={() => handleViewPdf()}>Visualizar {(!charge.charges || state.type_payment === 2) ? 'Boleto' : 'Boletos'}</Button>
                        <br />
                        <p className="mt-5">
                            <Link to={`/i/${!charge.charges ? charge.pub_identifier : charge.charges[0].pub_identifier}`} target="_blank">Visualize a fatura </Link>
                            e tenha acesso a todas as informações
                        </p>
                    </>
                  }
                  {
                    ((charge.pix || charge.charges?.[0]?.pix)) &&
                    <>
                        <h5 className="mt-4 mb-4">Para pagar com Pix</h5>
                        <Row>
                            <Col md={12} sm={12} xs={12} className='d-flex justify-content-center flex-column align-items-center'>
                                <span className="mt-3">Use o QR Code:</span>
                                <img width={200} src={!charge.charges ? charge.pix.qrcode : charge.charges[0].pix.qrcode } alt="QR Code" />
                                <span className="mt-3">ou use o Pix Copia E Cola:</span>
                                <br />
                                <span className="text-secondary" style={{wordBreak: 'break-all', maxWidth: '50%'}}>{!charge.charges ? charge.pix.copiaecola : charge.charges[0].pix.copiaecola}</span>
                                <CopyToClipboard onCopy={() => SuccessToast({ placeholder: 'Pix Copia e Cola foi copiada para área de transferência!' })} text={!charge.charges ? charge.pix.copiaecola : charge.charges[0].pix.copiaecola}>
                                    <Button className="mt-4 mr-2" color="success">Copiar Pix Copia e Cola</Button>
                                </CopyToClipboard>
                            </Col>
                            <Col md={12} sm={12} xs={12} className='d-flex justify-content-center flex-column align-items-center'>
                            <Card className="mt-4 card-type-payment is-bordered">
                                <div className="text-dark mt-2 mb-2 text-center">
                                <span className="text-dark font-weight-light" style={{ fontSize: 16, paddingLeft: 20, paddingRight: 20 }}>
                                    Status do Pagamento
                                </span>
                                </div>
                                {((charge.pix &&  ['ATIVA', 'REMOVIDA_PELO_USUARIO_RECEBEDOR', 'REMOVIDA_PELO_PSP'].includes(charge.pix?.situation)) || (['ATIVA', 'REMOVIDA_PELO_USUARIO_RECEBEDOR', 'REMOVIDA_PELO_PSP'].includes(charge.charges?.[0]?.pix.situation))) && (
                                    <CardHeader className="text-dark mt-2 mb-2 card-type-payment text-center">
                                    <div className="d-flex justify-content-center align-items-center">
                                        <FaSpinner size={18} className="mr-2 text-success" />
                                        <span className="text-dark font-weight-bold" style={{ fontSize: 16 }}>
                                          <Websocket data={charge.charges?.[0] ? charge.charges[0] : charge} retrieve={"situation"} callbackUpdateSituation={handleUpdatePixSituation} />
                                        </span>
                                        <br />
                                    </div>
                                    </CardHeader>
                                )}
                              {(
                                (charge.pix && charge.pix?.situation === "CONCLUIDA") ||
                              (charge.charges?.[0]?.pix.situation === "CONCLUIDA")) && (
                                <>

                              <CardHeader className="text-dark mt-2 mb-2 justify-content-center align-items-center text-center">
                                <div className="d-flex justify-content-center align-items-center">
                                  <FaCheck size={18} className="mr-3 text-success" />
                                  <span className="text-dark font-weight-bold text-center" style={{ fontSize: 16 }}>
                                        <Websocket data={charge.charges?.[0] ? charge.charges[0] : charge} retrieve={"situation"} callbackUpdateSituation={handleUpdatePixSituation} />
                                  </span>
                                </div>
                              </CardHeader>
                              {state.url && state.url.length > 0 && (
                                  <div className="text-dark mt-2 mb-2 text-center">
                                        <h6 className="text-dark font-weight-light" style={{ fontSize: 12, paddingLeft: 20, paddingRight: 20 }}>
                                        <span>Você será redirecionado em {timeleftToRedirect} segundos...</span>
                                        </h6>
                                  </div>
                              )}
                              </>
                            )}
                            </Card>
                          </Col>
                        </Row>
                    </>
                }
                {
                    charge.creditCardCharge && charge.creditCardCharge.status === 1 && !charge.pixCharge.pix? (
                        <h3>
                        <br />
                        <span className="mt-4 text-secondary">Agora efetue o restante do pagamento de {formatValue({ value: state.value_pix, currency: 'BRL' })} no PIX.</span>
                        <br />
                        <br />
                        <b>Não foi possível gerar a chave Pix para o pagamento.</b>
                        <br />
                        <p className="mt-5">
                            <Link to={`/i/${charge.pixCharge.pub_identifier}`} target="_blank">Visualize a fatura </Link>
                            e tenha acesso a todas as informações do PIX.
                        </p>
                        <br />
                        <span className="mt-4 text-secondary">Por favor, entre em contato com o administrador do PIX.</span>
                        <br />
                        </h3>
                    ) : charge.creditCardCharge && charge.creditCardCharge.status === 1 && charge.pixCharge && charge.pixCharge.pix ? (
                        <h3>
                        <span className="mt-4 text-secondary">Agora efetue o restante do pagamento de {formatValue({ value: charge.pixCharge.value, currency: 'BRL' })} no PIX.</span>
                        <br />
                        </h3>
                        ) : null
                }
                {
                    ( charge.pixCharge) &&
                    <>

                        <Row>
                            <Col md={12} sm={12} xs={12} className='d-flex justify-content-center flex-column align-items-center'>
                                <h5 className="mt-4 mb-4">Pagar com Pix o valor de {formatValue({ value: charge.pixCharge.value, currency: 'BRL' })}</h5>
                                <span className="mt-3">Use o QR Code:</span>
                                <img width={200} src={charge.pixCharge.pix.qrcode } alt="QR Code" />
                                <span className="mt-3">ou use o Pix Copia E Cola:</span>
                                <br />
                                <span className="text-secondary" style={{wordBreak: 'break-all', maxWidth: '50%'}}>{charge.pixCharge.pix.copiaecola}</span>
                                <CopyToClipboard onCopy={() => SuccessToast({ placeholder: 'Pix Copia e Cola foi copiada para área de transferência!' })} text={charge.pixCharge.pix.copiaecola}>
                                    <Button className="mt-4 mr-2" color="success">Copiar Pix Copia e Cola</Button>
                                </CopyToClipboard>
                            </Col>
                            <Col md={12} sm={12} xs={12} className='d-flex justify-content-center flex-column align-items-center'>
                            <Card className="mt-4 card-type-payment is-bordered">
                                <div className="text-dark mt-2 mb-2 text-center">
                                <span className="text-dark font-weight-light" style={{ fontSize: 16, paddingLeft: 20, paddingRight: 20 }}>
                                    Status do Pagamento
                                </span>
                                </div>
                                {((charge.pixCharge.pix && ['ATIVA', 'REMOVIDA_PELO_USUARIO_RECEBEDOR', 'REMOVIDA_PELO_PSP'].includes(charge.pixCharge.pix?.situation))) && (
                                    <CardHeader className="text-dark mt-2 mb-2 card-type-payment text-center">
                                    <div className="d-flex justify-content-center align-items-center">
                                        <FaSpinner size={18} className="mr-2 text-success" />
                                        <span className="text-dark font-weight-bold" style={{ fontSize: 16 }}>
                                            <Websocket data={charge.pixCharge} retrieve={"situation"} callbackUpdateSituation={handleUpdatePixSituation} />
                                        </span>
                                        <br />
                                    </div>
                                    </CardHeader>
                                )}

                                {((charge.pixCharge && ['CONCLUIDA'].includes(charge.pixCharge.pix?.situation))) && (
                                    <>
                                <CardHeader className="text-dark mt-2 mb-2 justify-content-center align-items-center text-center">
                                    <div className="d-flex justify-content-center align-items-center">
                                    <FaCheck size={18} className="mr-3 text-success" />
                                    <span className="text-dark font-weight-bold text-center" style={{ fontSize: 16 }}>
                                        <Websocket data={charge.pixCharge} retrieve={"situation"} callbackUpdateSituation={handleUpdatePixSituation} />
                                    </span>
                                    </div>
                                </CardHeader>
                                {state.url && state.url.length > 0 && (
                                    <div className="text-dark mt-2 mb-2 text-center">
                                        <h6 className="text-dark font-weight-light" style={{ fontSize: 12, paddingLeft: 20, paddingRight: 20 }}>
                                            <span>Você será redirecionado em {timeleftToRedirect} segundos...</span>
                                        </h6>
                                    </div>
                                )}
                                </>
                            )}
                            </Card>
                          </Col>
                        </Row>
                    </>
                }
                {
                    ((charge.charges && charge.charges[0].plug_boleto) || (charge && charge.plug_boleto)) && state.url && state.url.length > 0 &&
                    <>
                    <Row>
                        <Col md={12} sm={12} xs={12} className='d-flex justify-content-center flex-column align-items-center'>
                            <a href={state.url} className='text-success' title={`Você será redirecionado para o link: ${state.url}`}>
                            <Button style={{ width: '250px', fontSize: '18px', fontWeight: 'bold' }} className="mt-4 mr-2 btn btn-outline-success bg-white text-success">
                            Finalizar
                            </Button>
                          </a>
                        </Col>
                    </Row>
                    </>
                }
                </div>
              </div>
            </Col>
          </>
        }
      </Row>
      {
        state.payment_link_images.length > 0 &&
        <>
            <ModalDialog images={state.payment_link_images} isOpen={openDialog} setIsOpen={setOpenDialog} currentImg={currentImg} setCurrentImg={setCurrentImg} />
        </>
      }
      {
        !isMobile() &&
        <div className="text-center opacity-8 mt-3">
          Copyright &copy; WFPay Gestão Financeira {dateNow(null, 'YYYY')}
        </div>
      }
    </>
  )
}

const mapStateToProps = (state) => ({
  loading: state.AppConfig.loading,
})

const mapDispatchToProps = (dispatch) => ({
  setLoading: (enable, text = null) => dispatch(setLoading(enable, text))
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentLinkPub);
